<template>
  <div class="err_device">
    <div class="card_box">
      <!-- 标题 -->
      <div class="title_box">
        <div></div>
        <span>异常设备</span>
      </div>

      <!-- 搜索框 -->
      <div class="selector">
        <div class="select_box">
          <el-select
            v-model="id_operator"
            placeholder="全部运营商"
            style="margin-right: 14px"
            class="w200"
            @change="selectOperator"
            v-supAdmin
          >
            <el-option
              v-for="item in operatorList"
              :key="item.id_operator"
              :label="item.name_operator"
              :value="item.id_operator"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="id_project"
            placeholder="全部项目"
            style="margin-right: 14px"
            class="w200"
            @change="selectProject"
          >
            <el-option
              v-for="item in computedProject"
              :key="item.id"
              :label="item.name_project"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="id_building"
            placeholder="全部楼栋"
            style="margin-right: 14px"
            class="w200"
            @change="selectBuilding"
          >
            <el-option
              v-for="item in buildingList"
              :key="item.id"
              :label="item.name_building"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="floor"
            placeholder="全部楼层"
            style="margin-right: 14px;"
            class="w200 mt"
          >
            <el-option
              v-for="item in floorList"
              :key="item.floor"
              :label="item.floor + 'F'"
              :value="item.floor"
            >
            </el-option>
          </el-select>
        </div>

        <div class="btn_box">
          <el-button type="primary" icon="el-icon-search" @click="search"
            >查询</el-button
          >
          <el-button type="success" icon="el-icon-refresh-left" @click="reset"
            >重置</el-button
          >
        </div>
      </div>

      <!--表格 -->
      <div class="table_box">
        <!-- 表格 -->
        <el-table
          :data="deviceList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb'
          }"
          size="small"
        >
          <el-table-column label="" width="40">
            <template v-slot="scope">
              <span
                v-if="scope.row.locked"
                class="el-icon-lock"
                style="font-size:18px"
              ></span>
              <span
                v-else
                class="el-icon-unlock"
                style="font-size:18px;color:#ccc"
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="level === 100"
            prop="name_operator"
            label="运营商"
          ></el-table-column>
          <el-table-column prop="name_project" label="项目"></el-table-column>
          <el-table-column label="设备名称">
            <template v-slot="scope">
              <span class="edit">
                {{ scope.row.addr }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="IMEI">
            <template v-slot="scope">
              <span class="edit">
                {{ scope.row.id_device }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name_building" label="楼栋"></el-table-column>
          <el-table-column label="楼层">
            <template v-slot="scope">
              <span> {{ scope.row.floor }}F </span>
            </template>
          </el-table-column>
          <el-table-column label="信号值">
            <template v-slot="scope">
              <span>
                {{ scope.row.rssi || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="运行状态">
            <template v-slot="scope">
              <span>
                {{ scope.row.run ? '开机' : '关机' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="异常状态">
            <template v-slot="scope">
              <span v-if="scope.row.unusual_flag & 1" class="primary"
                >超长待机</span
              >
              <span v-if="scope.row.unusual_flag & 2" class="offline"
                >超长运行</span
              >
              <span v-if="scope.row.unusual_flag & 4" class="del">故障</span>
            </template>
          </el-table-column>

          <el-table-column label="管理员">
            <template v-slot="scope">
              <span>
                {{ scope.row.account.real_name || '--' }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template v-slot="scope">
              <div class="table_control">
                <img
                  src="../../../assets/table_operation.png"
                  alt=""
                  title="操作"
                  @click="toControlDevice(scope.row)"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetRealDeviceListApi } from '@/api'
import operator from '@/mixins/operator.js'
import project from '@/mixins/project.js'
import building from '@/mixins/building.js'
import floor from '@/mixins/floor.js'

export default {
  name: 'err_device',
  mixins: [operator, project, building, floor],
  data() {
    return {
      // 权限级别
      level: '',
      // 分页
      page_id: 0,
      page_num: 20,
      total: 0,
      // 运营商id
      id_operator: '',
      // 项目id
      id_project: '',
      // 楼栋
      id_building: '',
      // 楼层
      floor: '',
      // 设备列表
      deviceList: []
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getdeviceList()
    },

    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getdeviceList()
    },

    // 选中运营商
    selectOperator() {
      this.id_project = ''
      this.id_building = ''
      this.id_floor = ''
      this.getBuildingList({ id_operator: this.id_operator })
      this.getFloorList({ id_operator: this.id_operator })
    },

    // 选中项目
    selectProject() {
      this.id_building = ''
      this.floor = ''
      this.getBuildingList({ id_project: this.id_project })
      this.getFloorList({ id_project: this.id_project })
    },

    // 选中楼栋
    selectBuilding() {
      this.floor = ''
      const params = {
        id_building: this.id_building
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      this.getFloorList(params)
    },

    // 获取设备列表
    async getdeviceList() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num,
        unusual_flag: 7
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.id_building) {
        params.id_building = this.id_building
      }
      if (this.floor) {
        params.floor = this.floor
      }
      const { ret, data, msg } = await GetRealDeviceListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.deviceList = data.data
    },

    // 查询
    search() {
      this.page_id = 0
      this.getdeviceList()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.id_operator = ''
      this.id_project = ''
      this.id_building = ''
      this.floor = ''
      this.getBuildingList()
      this.getFloorList()
      this.getdeviceList()
    },

    // 去控制设备界面
    toControlDevice(row) {
      this.$router.push('/device/device_control?id=' + row.id_device)
    }
  },
  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getProjectList()
    this.getBuildingList()
    this.getFloorList()
    this.getdeviceList()
  },

  computed: {
    // 计算项目
    computedProject() {
      if (this.id_operator) {
        return this.projectList.filter(i => {
          return i.id_operator === this.id_operator
        })
      } else {
        return this.projectList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.err_device {
  padding: 0 22px;
}

.card_box {
  background-color: #fff;
  min-height: calc(100vh - 160px);
  margin-top: 24px;
}

.title_box {
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebedf2;

  div {
    width: 4px;
    height: 18px;
    background: linear-gradient(-75deg, #50e379, #50e398);
    box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
    margin: 0 11px 0 26px;
  }

  span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #12203e;
  }
}

.selector {
  padding: 20px 22px;
  display: flex;
  justify-content: space-between;

  .select_box {
    @media screen and (max-width: 1380px) {
      .mt {
        margin-top: 10px;
      }
    }
  }

  .btn_box {
    flex: 0 0 190px;
  }
}

.table_box {
  padding: 0 22px;
}
</style>
